<template>
  <div id="app" style="background-color: white">
    <div class="banner" v-on:click="fullscreen()">
      <a href="/">
        <img height="80vw" class="logo" src="./logo.png" />
      </a>
      <a v-if="timestamp" class="timestamp">{{ timestamp }}</a>
    </div>

    <div v-if="isSala">
      <DisplaySala :piatti="piatti" />
    </div>
    <div v-else>
      <div class="wrapper" v-if="aula">
        <div id="text">
          <h1>Aula</h1>
          <h3>{{ nomeAula }}</h3>
          <hr />
          <p>
            Scansiona il QRCode utilizzando l'app per segnare la tua presenza
            {{ lockok ? ".." : "" }} {{ lockno ? "." : "" }}
          </p>

          <div class="bar-container" v-on:click="fullscreen()">
            <div v-bind:class="barcolor" v-bind:style="{ width: sizeperc + '%' }">
              {{ countdown }}
            </div>
          </div>
        </div>
        <br />
        <div>
          <qrcode-vue class="qr" v-if="code > ''" :value="code" :size="size"></qrcode-vue>
        </div>
      </div>

      <div class="wrapper" v-if="!aula && !tavolo">
        <div class="input">
          <h1>Inserire il codice dell'aula</h1>
          <input type="text" v-model="codiceaula" />
          <a v-on:click="salvaaula" :style="{ opacity: codiceaula != '' ? '100' : '0' }"
            >Conferma</a
          >
        </div>
      </div>

      <div class="tavolo-giuria" v-if="tavolo">
        <div class="landscape">
          <div style="">
            <h3>Tavolo N. {{ tavolo }}</h3>
            <qrcode-vue
              class="qr"
              v-if="code > ''"
              :value="code"
              :size="size"
            ></qrcode-vue>
          </div>
          <div>
            <Piatto class="piatto" :piatti="piatti" :tavolo="tavolo" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qrcodeVue from "qrcode.vue";
import apiclient from "./apiclient.js";
import DisplaySala from "./components/sala.vue";
import Piatto from "./components/ultimopiatto.vue";

export default {
  name: "app",
  data: function () {
    return {
      aula: "",
      codiceaula: "",
      nomeAula: "",
      code: "",
      countdown: 0,
      sizeperc: 100,
      timer: {},
      barcolor: "bargreen",
      size: 240,
      wakeLock: {},
      lockok: false,
      lockno: false,
      abilitato: false,
      clientIP: "",
      tavolo: null,
      url: "https://docenti.scuolacucina.it/#/loginesterni?otp=",
      // url: 'https://devalmadocet.gnet.it/#/loginesterni?otp=',
      isSala: false,
      piatti: [],
      timestamp: null,
      candidato: { Id: "", foto: "" },
    };
  },
  components: {
    qrcodeVue,
    DisplaySala,
    Piatto,
  },
  mounted: async function () {
    this.getParamFromUrl();

    if (location.search > "" && this.tavolo) {
      console.log("RESETTO AULA");
      localStorage.setItem("alma_aula", "");
    }
    if (localStorage.getItem("alma_aula")) this.aula = localStorage.getItem("alma_aula");
    if (this.aula || this.tavolo) {
      this.getIp();
      // this.gestionetotp();
    }
    this.addWakelock();

    if (this.isSala || this.tavolo) {
      await this.getPiattiInUscita();

      setInterval(async () => {
        await this.getPiattiInUscita();
      }, 15000);
    }
  },
  created() {
    // this.controlla();
  },
  methods: {
    getParamFromUrl() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.tavolo = urlParams.get("tavolo");
      this.aula = urlParams.get("aula");
      this.isSala = urlParams.get("isSala");
      if (this.aula && this.tavolo) {
        this.aula = "";
        this.tavolo = "";
        alert("Indirizzo errato!");
      }
      // if(this.tavolo){
      //   console.log('Parametro tavolo', this.tavolo);
      //   this.gestionetotp();
      // }
      // if(this.aula){
      //   console.log('Parametro aula', this.aula);
      //   this.getIp();
      // }
    },

    getIp() {
      console.log("getIp");
      const xhr = new XMLHttpRequest();
      xhr.open("GET", "/getIP", true);

      let self = this;

      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          // La risposta contiene l'indirizzo IP restituito dal server
          const jsonData = JSON.parse(xhr.responseText);
          self.clientIP = jsonData.userIp;
          console.log(self.clientIP);
          self.controlla();
        } else {
          console.error("Qualcosa è andato storto nella richiesta.");
        }
      };

      xhr.send();
    },

    controlla() {
      console.log("controlla - aula:", this.aula, ", tavolo:", this.tavolo);
      var self = this;
      // if(this.aula){
      //const clientIP = window.location.hostname;
      //console.log('Questo ==>',this.clientIP)
      var data = {
        callname: "addIP",
        ip: this.clientIP,
        aulaId: this.aula,
        tavolo: this.tavolo,
      };
      //console.log("data =====>", data);
      apiclient.addIP(data).then(function (resp) {
        console.log(resp);
        data = { callname: "getStato", ip: self.clientIP };
        apiclient.getStato(data).then(function (stato) {
          //console.log('ricevuto:'+data.callname);
          console.log("STATO = ", stato);
          if (stato) {
            console.log("ABILITATO APP TABLET");
            self.abilitato = true;
            self.gestionetotp();
            if (self.aula) self.getAula();
          } else console.log("NON ABILITATO APP TABLET");
        });
      });

      // }
    },

    getAula: async function () {
      var self = this;
      if (this.aula) {
        var data = { callname: "getAula" };
        await apiclient.getcandidatura2(data).then(function (resp) {
          console.log(resp);
          if (resp) {
            for (let aula of resp) {
              //console.log(aula.Name);
              if (aula.Key__c == self.aula) self.nomeAula = aula.Name;
              //console.log(self.nomeAula);
            }
          }
        });
      }
    },

    salvaaula: function () {
      console.log("salvaaula");
      this.aula = this.codiceaula;
      localStorage.setItem("alma_aula", this.aula);
      window.location = "/"; // restart
    },
    fullscreen: function () {
      let theapp = document.getElementById("app");
      if (theapp) {
        theapp.requestFullscreen();
      }
    },
    async addWakelock() {
      console.log("addWakelock");
      try {
        if ("wakeLock" in navigator) {
          this.wakeLock = await navigator.wakeLock.request("screen");
          this.lockok = true;
          console.log("lock ok");

          setTimeout(() => {
            this.releaseWakelock(); //Dopo 15 minuti rilascia il blocco schermo attivo.
          }, 15 * 60 * 1000); //Decorsi i 15 minuti lo schermo si spegnerà dopo il tempo settato nelle impostazioni schermo.
        } else {
          this.lockno = true;
          console.log("lock not supported");
        }
      } catch (e) {
        console.log(e);
      }
    },
    releaseWakelock() {
      if (this.wakeLock) {
        this.wakeLock.release();
        this.lockok = false;
        console.log("lock released");
      }
    },

    gestionetotp: async function () {
      if (this.abilitato /* || this.tavolo*/) {
        var data;
        if (this.tavolo) {
          data = { callname: "generateTOTPGiudici" };
          this.code =
            this.url + (await apiclient.getcandidatura2(data)) + "&tav=" + this.tavolo;
        } else if (this.aula) {
          data = { callname: "generateTOTP", aulaId: this.aula, ip: this.clientIP };
          this.code = (await apiclient.getcandidatura2(data)) + ";" + this.aula;
          localStorage.setItem("alma_aula", "");
        }
        this.timer = setInterval(
          function (self) {
            var now = new Date().getTime(); // Ottieni il timestamp attuale in millisecondi
            var epochSeconds = Math.floor(now / 1000); // Secondi interi
            var msPart = now % 1000; // Parte millisecondi

            // Calcola il countdown preciso (includendo i millisecondi)
            var countdownExact = 30 - ((epochSeconds % 30) + msPart / 1000);

            self.countdown = Math.floor(countdownExact); // Mostra secondi interi
            self.sizeperc = ((countdownExact / 30) * 100).toFixed(2); // Maggiore precisione per una transizione fluida

            self.barcolor = self.countdown < 5 ? "barred" : "bargreen";

            // Controllo per eseguire codice ogni 30 secondi (solo quando cambia il secondo intero)
            if (epochSeconds % 30 === 0 && msPart < 100) {
              console.log("new code");
              apiclient.getcandidatura2(data).then((resp) => {
                console.log(resp);
                if (self.tavolo)
                  self.$set(self, "code", self.url + resp + "&tav=" + self.tavolo);
                else self.$set(self, "code", resp + ";" + self.aula);
              });
            }
          },
          100, // Aggiorna ogni 100ms per maggiore fluidità
          this
        );
      }
    },

    getFormatDate() {
      console.log("getFormatDate");
      let date = new Date();
      return date.toLocaleTimeString();
    },

    getPiattiInUscita: async function () {
      var data = { callname: "getPiattiInUscita" };

      let resp = await apiclient.getcandidatura2(data);
      console.log("getPiattiInUscita", resp);

      await this.getAttachment(resp);

      this.piatti = resp;
      this.timestamp = this.getFormatDate();
      console.log("TIMESTAMP:", this.timestamp, this.piatti);
    },

    getAttachment: async function (resp) {
      if (this.tavolo) {
        for (let piatto of resp) {
          if (piatto.Tavolo == this.tavolo) {
            console.log(
              "piatto.IdCandidato",
              piatto.IdCandidato,
              "this.IdCandidato",
              this.candidato.Id,
              piatto.IdCandidato != this.candidato.Id
            );
            if (piatto.IdCandidato != this.candidato.Id) {
              this.candidato.Id = piatto.IdCandidato;
              let res = await apiclient.getcandidatura2({
                callname: "getAttachment",
                parentId: this.candidato.Id,
              });

              console.log("getAttachment", res);
              if (res)
                this.candidato.foto =
                  res == "Niente file" ? res : "data:image/jpeg;base64," + res;
              piatto["FotoCandidato"] = this.candidato.foto;
            } else {
              piatto["FotoCandidato"] = this.candidato.foto;
            }
          }
        }
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

:root {
  --main: #283c50;
  --main-lighter: #3b5674;
}
* {
  margin: 0;
  padding: 0;
  font-family: Montserrat, Avenir, Arial, sans-serif;
}
*:focus {
  outline: none;
}

.logo {
  margin: 0.5vh 1vw 0.5vh;
}

.banner {
  background-color: var(--main);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.qr {
  /* width: 80%; */
  max-height: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}

.bargreen {
  background-color: #283c50;
  text-align: center;
  height: 1.5em;
}

.barred {
  background-color: lightcoral;
  text-align: center;
  height: 1.5em;
}

.bar-container {
  color: white;
  width: 100%;
  height: 1.5em;
  background-color: #b9b9b9;
  margin-top: 2vh;
  border-radius: 150px;
  width: 100%;
  overflow: hidden;
}

.tavolo-giuria {
  padding: 2em 2em 0 2em;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  padding-top: 2em;
  /* padding: 2em 2em 0 2em; */
}

.text {
  max-width: 50%;
  word-wrap: break-word;
}
h1 {
  font-weight: 600;
}
h3 {
  font-weight: 700;
  font-size: 50px;
  text-align: center;
}
p {
  margin-top: 3vh;
}

.input {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.input input {
  margin-top: 4vh;
  border: none;
  border-bottom: 2px solid #cbcbcb;
  font-size: 35px;
  text-align: center;
}
.input a {
  text-decoration: none;
  color: white;
  background-color: var(--main);
  padding: 2.5vh 2vw 2.5vh;
  border-radius: 250px;
  margin-top: 3vh;
  transition-duration: 150ms;
}
.input a:hover {
  background-color: var(--main-lighter);
  transition-duration: 150ms;
}
.piatto {
  /* padding-top: 10%;
  width: 80%;
  max-height: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center; */
  margin-top: 10%;
}
.landscape {
}

.timestamp {
  margin-left: auto;
  padding-right: 2vw;
  color: white;
  font-size: 3vw;
}

@media screen and (orientation: portrait) {
  .wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .text {
    max-width: 100%;
  }

  #text {
    text-align: center;
    max-width: 100%;
  }
}

@media screen and (orientation: landscape) {
  .landscape {
    display: flex;
    flex-direction: row;
  }
  .piatto {
    max-width: 500px;
    width: 100%;
    max-height: 70%;
    margin-left: 20%;
  }
  h3 {
    text-align: left;
  }
  .qr {
    align-self: flex-start; /* Posiziona il QR code sulla destra in modalità paesaggio */
  }

  .wrapper {
    flex-direction: row;
  }

  .text {
    max-width: 50%;
  }
}
</style>
